export const STATUS = {
    NEW: "NEW",
    PROCESSING: "PROCESSING",
    CANCEL: "CANCELLED",
    REFUND: "REFUND",
    COMPLETED: "COMPLETED"
}

export const STATUS_KITCHEN = {
    NEW: "NEW",
    PROCESSING: "PROCESSING",
    READY: "READY_TO_PICK_UP",
    COMPLETED: "PICKED_UP",
    CANCELLED: "CANCELLED"
}

export const ORDER_TYPE = {
    SELF: "SELF_PICKUP",
    DELIVERY: "DELIVERY"
}

export const DATA_OUTLET = {
    NAME: "Cashie Lab",
}

export const ROLE = {
    KITCHEN: "KITCHEN",
    ADMIN: "ADMIN",
    CASHIER: "CASHIER"
}

export const HISTORY = {
    SOURCE: {
        CAT: "CATALOG",
    },
    ORDER_TYPE: {
        SELF_PICKUP: "Ambil Sendiri",
        DELIVERY: "Delivery"
    }
}

export const MODAL_TEXT = {
    MODAL_PAYMENT_SUCCESS: {
        TITLE: "Orderan Diterima!",
        DESC: "Daftar orderan dapat dilihat di Riwayat Pesanan"
    },
    MODAL_EDIT_SUCCESS: {
        TITLE: "Orderan Berhasil Diedit!",
        DESC: "Daftar orderan dapat dilihat di Riwayat Pesanan"
    },
    MODAL_EDIT_FAILED: {
        TITLE: "Orderan Gagal Diedit!",
        DESC: "Daftar orderan dapat dilihat di Riwayat Pesanan"
    },
    MODAL_PAYMENT_FAILED: {
        TITLE: "Orderan Ditolak!"
    },
    MODAL_PENDING_CONFIRM: {
        TITLE: "Orderan Disimpan Sementara!",
        DESC: "Orderan akan disimpan sementara saat offline"
    },
    MODAL_PENDING_SUCCESS: {
        TITLE: "Pending Order Berhasil Dikirim!",
        DESC: "Daftar orderan dapat dilihat di Riwayat Pesanan"
    },
    MODAL_PENDING_FAILED: {
        TITLE: "Pending Order Tidak Berhasil Terkirim!"
    },
    MODAL_DISCOUNT_FAILED: {
        TITLE: "Diskon tidak dapat dipakai",
        DESC: "Periksa kembali ketentuan diskon",
        CONFIRM_BTN: "Periksa Kembali"
    },
    MODAL_DISCOUNT_NULL: {
        TITLE: "Pilih Diskon Terlebih Dahulu!",
        DESC: "Pastikan diskon sudah terpilih",
        CONFIRM_BTN: "Periksa Kembali"
    },
    MODAL_PAYMENTMETHOD_FAILED: {
        TITLE: "Pilih Pembayaran Terlebih Dahulu!",
        DESC: "Pastikan pembayaran sudah terpilih",
        CONFIRM_BTN: "Periksa Kembali"
    },
    MODAL_DELETE_CONFIRM: {
        TITLE: "Apakah Kamu Yakin?",
        DESC: "Kamu tidak akan bisa mengembalikan ini",
        CONFIRM_BTN: "Ya, hapus",
        CANCEL_BTN: "Tidak, tidak jadi"
    },
    MODAL_CANCEL_CONFIRM: {
        TITLE: "Apakah Kamu Yakin?",
        DESC: "Kamu tidak akan bisa mengembalikan ini",
        CONFIRM_BTN: "Ya, batalkan",
        CANCEL_BTN: "Tidak, tidak jadi",
        REASON_LABEL: "Alasan Pembatalan",
        REASON_PLACEHOLDER: "Tulis alasan pembatalan disini"
    },
    MODAL_DELETE_SUCCESS: {
        TITLE: "Dihapus!",
        DESC: "Item sudah dihapus",
    },
    MODAL_OFFLINE: {
        TITLE: "Koneksi terputus!",
        DESC: "Periksa kembali koneksi Anda",
    },
    MODAL_ONLINE: {
        TITLE: "Dihapus!",
        DESC: "Item sudah dihapus",
    },
    MODAL_UPDATE_SUCCESS: {
        TITLE: "Status Order Berhasil Diubah!",
        DESC: "Status order bisa dilihat di kanan atas",
    },
    MODAL_UPDATE_FAILED: {
        TITLE: "Perubahan Status Order Ditolak!",
    },
    MODAL_MENU_FAILED: {
        TITLE: "Ada opsi yang belum diisi!",
        DESC: "Pastikan semua opsi terisi",
        CONFIRM_BTN: "Periksa Kembali"
    },
    MODAL_STOCK_FAILED: {
        TITLE: "Ada field yang belum diisi!",
        DESC: "Pastikan semua field terisi",
        CONFIRM_BTN: "Periksa Kembali"
    },
    MODAL_STOCK_SUCCESS: {
        TITLE: "Permintaan Anda Sudah Terkirim",
        DESC: "Permintaan akan diperiksa terlebih dahulu",
        CONFIRM_BTN: "Mengerti"
    },
    MODAL_PENDING_STATUS: {
        TITLE: "Pembayaran Disimpan Sementara!",
        DESC: "Pembayaran akan disimpan sementara saat offline"
    },
    KITCHEN: {
        LOGOUT_BTN: "Keluar",
        STATUS: {
            WAIT: "Menunggu",
            PROCESS: "Diproses",
            READY: "Siap Diambil"
        },
        BODY: {
            NO_ORDER: "Nomor Order",
            TIME_ORDER: "Waktu Order",
            ORDER_LIST: "Daftar Pesanan",
            NOTES_TITLE: "Catatan",
            BUTTON: {
                PROCESS: "Proses Pesanan",
                COMPLETE: "Pesanan Siap Diambil"
            }
        }
    },
    STATUS_ORDER: {
        NEW: "Diterima",
        PROCESSING: "Dimasak",
        READY_SELF: "Siap Diambil",
        READY_DELIVERY: "Siap Diantar",
        PICKED_UP: "Sudah Diambil"
    },
    CUST_CONTACT_PLACEHOLDER: "Kontak",
    CUST_NAME_PLACEHOLDER: "Customer",
    CUST_NAME_CATALOG: "CATALOG"
}

export const APIUrl = "https://ipv4.icanhazip.com/";
export const pingUrl = APIUrl;
export const polling = { enabled: true, url: pingUrl, interval: 5000 };