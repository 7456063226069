import axios from 'axios';
import moment from 'moment';
import "moment-timezone";
export default class Api {
  constructor() {
    this.api_token = null;
    this.client = null;
    this.api_url = process.env.REACT_APP_BASE_URL;
  }
  init = () => {
    this.api_token = localStorage.getItem("accessToken");
    let headers = {
      Accept: "application/json"
    };
    if (this.api_token) {
      headers.Authorization = `Bearer ${this.api_token
        }`;
    }
    this.client = axios.create({
      baseURL: this.api_url,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
    });
    return this.client
  };
  getAuthData = () => {
    return this.init().get("/auth/get-profile").then(function (response) {
      let timeNow = moment.tz(new Date(), "Asia/Jakarta").format("HH:mm");
      localStorage.setItem(
        "cashier",
        response.data.data.firstName + " " + response.data.data.lastName
      );
      localStorage.setItem("tax", response.data.data.tax)
      localStorage.setItem("campaign", JSON.stringify(response.data.data.campaign))
      localStorage.setItem("outlet", JSON.stringify(response.data.data.outlet))
      localStorage.setItem("userUID", response.data.data.uuid)
      localStorage.setItem("globalPrices", JSON.stringify(response.data.data.globalPrices));
      const d = new Date();
      let day = d.getDay();

      let indexCurrentGlobalPrice = JSON.parse(localStorage.getItem("globalPrices")).findIndex(p => p.startTime <= timeNow && timeNow <= p.endTime && ( p?.activeDay==null || p?.activeDay?.includes(`${day}`)));
      if (indexCurrentGlobalPrice == -1) {
        localStorage.setItem("currentGlobalPrice", response.data.data.outlet.globalPrice);
      } else {
        localStorage.setItem("currentGlobalPrice", JSON.parse(localStorage.getItem("globalPrices"))[indexCurrentGlobalPrice].price);
      }
      localStorage.setItem("setRefresh", 0);
      localStorage.setItem("defaultGlobalPrice", response.data.data.outlet.globalPrice);

      response.data.data.printer ? response.data.data.printer.header ? localStorage.setItem("printingHeader", response.data.data.printer.header) : localStorage.setItem("printingHeader", "") : localStorage.setItem("printingHeader", "")
      response.data.data.printer ? response.data.data.printer.footer ? localStorage.setItem("printingFooter", response.data.data.printer.footer) : localStorage.setItem("printingFooter", "") : localStorage.setItem("printingFooter", "")
      response.data.data.printer ? response.data.data.printer.imageUrl ? localStorage.setItem("logo", process.env.REACT_APP_IMAGE_URL + response.data.data.printer.imageUrl) : localStorage.setItem("logo", "https://admin.cashielab.com/images/logo.png") : localStorage.setItem("logo", "https://admin.cashielab.com/images/logo.png")
    });
  };
  getCategoryData = () => {
    return this.init().get("/category?sort=order ASC").then(function (response) {
      localStorage.setItem("category", JSON.stringify(response.data.data));
    });
  };
  getMenuData = async () => {
    const responses = await this.init().get("/item");
    localStorage.setItem("menu", JSON.stringify(responses.data.data));
  };
  getPaymentMethod = () => {
    return this.init()
      .get("/payment-method?sort=order ASC&isActive=true")
      .then(function (response) {
        localStorage.setItem(
          "paymentMethod",
          JSON.stringify(response.data.data)
        );
      });
  };
  getHistoryData = async () => {
    const response = await this.init().get("/order?sort=createdAt DESC");

    let history_data = localStorage.getItem("history") ? JSON.parse(localStorage.getItem("history")) : []
    let pending_data = history_data.filter(data => data.orderNumber.includes("pending"))
    pending_data = pending_data.length > 0 ? pending_data.concat(response.data.data) : pending_data.concat(response.data.data)
    localStorage.setItem("history", JSON.stringify(pending_data));
    return response;
  };
  updateStatus = (params) => {
    return this.init().post("/order/update", params)
  };
  createPayment = (params) => {
    return this.init().post("/order", params)
  };
  createBulkPayment = (params) => {
    return this.init().post("/order/create-bulk", params)
  };
  updateBulkStatus = (params) => {
    return this.init().post("/order/update-bulk", params)
  };
  getNewKitchenData = async () => {
    return await this.init().get("/order?sort=createdAt ASC&kitchenStatus=NEW").then(function (response) {
      localStorage.setItem("newKitchen", JSON.stringify(response.data.data));
    });
  };
  getProcessKitchenData = async () => {
    return await this.init().get("/order?sort=createdAt ASC&kitchenStatus=PROCESSING").then(function (response) {
      localStorage.setItem("processKitchen", JSON.stringify(response.data.data));
    });
  };
  getReadyKitchenData = async () => {
    return await this.init().get("/order?sort=createdAt ASC&kitchenStatus=READY_TO_PICK_UP").then(function (response) {
      localStorage.setItem("readyKitchen", JSON.stringify(response.data.data));
    });
  };
  getOrderSummary = (params) => {
    return this.init().post("/order/summary", params);
  };
  getOrderClosing = (params) => {
    return this.init().post("/order/closing", params);
  };
  getStock = () => {
    return this.init().get("/item/stocks/data");
  };
  updateStock = (params) => {
    return this.init().post("/item/stock-change", params);
  };
  updateOrder = (params) => {
    return this.init().post("/order/update", params)
  };
}
