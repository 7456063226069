import React, { useEffect, useState } from 'react';
import "./index.scss";
import Api from '../../api-module';
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

const FrontScreen = () => {
    const [bannerUrl, setBannerUrl] = useState("");
    const [cart, setCart] = useState([]);
    const [diskonActive, setDiskonActive] = useState(false);
    const [subDiskonActive, setSubDiskonActive] = useState(false);
    const [subDiskonIndex, setSubDiskonIndex] = useState(-1);
    const [selectedDiscount, setSelectedDiscount] = useState({
        uuid: null,
        type: "",
        name: "",
        code: "",
        value: 0,
        minOrder: "",
        itemId: "-1"
    });
    const [subtotal, setSubtotal] = useState(0)
    const [pajakActive, setPajakActive] = useState(false);
    const [pajakNominal, setPajakNominal] = useState();

    const api = new Api();

    const convertRupiah = (numb) => {
        numb = Math.round(numb);
        if (isNaN(numb)) return "";

        const format = numb.toString().split("").reverse().join("");
        const convert = format.match(/\d{1,3}/g);
        const rupiah = `Rp ${numb.toString().substring(0, 1) == "-" ? "-" : ""}` + convert.join(".").split("").reverse().join("");
        return rupiah;
    };

    const CustomerData = () => {
        return (
            <div className='header'>
                {

                    cart != undefined ?
                        cart.length != 0 ?
                            <div className='transact_header'>
                                <div className='transact_code'>{
                                    new Intl.DateTimeFormat("id", {
                                        dateStyle: "full",

                                    })
                                        .format(new Date(Date.now()))

                                }</div>
                            </div> : <></> : <></>
                }

            </div>

        )
    }

    const Transaction = () => {

        return (
            <div className='transact_list '>
                {
                    cart.map((item, index) => (

                        <div className='transact_list__item' key={index}>
                            <Row>
                                <Col md={12} sm={12} xs={12}>

                                    <Row>
                                        <Col md={1} sm={1} xs={1}>
                                            <div className='transact_list__item_qty'>x{item.qty}</div>
                                        </Col>
                                        <Col md={7} sm={7} xs={7}>
                                            <div className='transact_list__item_title'>{item.title}</div>

                                            <div className='transact_list__item_price'>{convertRupiah(item.price)}</div>
                                        </Col>
                                        <Col md={4} sm={4} xs={4}>

                                            <div className={`transact_list__item_total ${subDiskonActive ? selectedDiscount.itemId == item.id ? subDiskonIndex == index ? 'discounted' : '' : '' : ''}`}>{convertRupiah(item.price * item.qty)}</div>
                                            {
                                                subDiskonActive ? selectedDiscount.itemId == item.id ? subDiskonIndex == index ?
                                                    <div className='transact_list__item_total'>{convertRupiah((item.price * item.qty) - selectedDiscount.value > 0 ? (item.price * item.qty) - selectedDiscount.value : 0)}</div> :
                                                    '' : '' : ''
                                            }

                                        </Col>
                                    </Row>
                                    {
                                        item.modifier.map((modif_item) => (
                                            <Row key={modif_item.modif_id}>
                                                <Col md={1} sm={1} xs={1}></Col>
                                                <Col md={7} sm={7} xs={7}>
                                                    <div className='transact_list__item_modifier_title'>{modif_item.modifier_title} : {modif_item.modifier_value}</div>
                                                </Col>
                                                <Col md={4} sm={4} xs={4}>

                                                    <div className='transact_list__item_modifier_price'>{convertRupiah(modif_item.modifier_price)}</div>
                                                </Col>
                                            </Row>
                                        ))
                                    }
                                    {
                                        item.note ?
                                            <Row>
                                                <Col md={1} sm={1} xs={1}></Col>
                                                <Col md={11} sm={11} xs={11}>
                                                    <div className='transact_list__item_modifier_title memo'><FontAwesomeIcon className='memo-icon' icon={solid('clipboard')} /><span>{item.note}</span></div>
                                                </Col>
                                            </Row> : <></>
                                    }

                                </Col>
                            </Row>


                        </div>
                    ))
                }
            </div>
        )
    }

    const SummaryCart = () => {
        return (
            <div className='summary '>
                <div className='summary_item'>
                    <div className='summary_item__title'>Subtotal</div>
                    {
                        subDiskonActive ?
                            <div className='summary_item__price'>{convertRupiah(subtotal - selectedDiscount.value)}</div> :
                            <div className='summary_item__price'>{convertRupiah(subtotal)}</div>
                    }
                </div>
                {
                    diskonActive ?
                        <div className='summary_item'>
                            <div className='summary_item__title'>Diskon</div>
                            <div className='summary_item__price'>-{convertRupiah(selectedDiscount.value)}</div>
                        </div>
                        :
                        <></>
                }
                {
                    pajakActive ?
                        diskonActive || subDiskonActive ?
                            <div className='summary_item'>
                                <div className='summary_item__title'>Pajak {pajakNominal}%</div>
                                <div className='summary_item__price'>{convertRupiah((subtotal - selectedDiscount.value) * (pajakNominal / 100))}</div>
                            </div>
                            :
                            <div className='summary_item'>
                                <div className='summary_item__title'>Pajak {pajakNominal}%</div>
                                <div className='summary_item__price'>{convertRupiah(subtotal * (pajakNominal / 100))}</div>
                            </div>
                        :
                        <></>
                }
                <div className='summary_total'>
                    <div className='summary_total__title'>Total</div>
                    {
                        pajakActive ?
                            diskonActive || subDiskonActive ?
                                <div className='summary_total__price'>{convertRupiah(subtotal - selectedDiscount.value + ((subtotal - selectedDiscount.value) * (pajakNominal / 100)))}</div>
                                :
                                <div className='summary_total__price'>{convertRupiah(subtotal + (subtotal * (pajakNominal / 100)))}</div>
                            :
                            diskonActive || subDiskonActive ?
                                <div className='summary_total__price'>{convertRupiah(subtotal - selectedDiscount.value)}</div>
                                :
                                <div className='summary_total__price'>{convertRupiah(subtotal)}</div>
                    }

                </div>
            </div>
        )
    }

    const EmptyCart = () => {
        return (
            <div className='empty '>
                <img src='./empty-cart.svg'></img>
                <div className='empty_title'>Ayo mulai memesan</div>
            </div>
        )
    }

    useEffect(() => {
        localStorage.getItem("outlet") ? setBannerUrl(JSON.parse(localStorage.getItem("outlet")).bannerUrl) : api.getAuthData()
        localStorage.getItem("currentCart") ? setCart(JSON.parse(localStorage.getItem("currentCart"))) : []

        if (localStorage.getItem("cartData")) {
            setDiskonActive(JSON.parse(localStorage.getItem("cartData")).setDiskonActive)
            setPajakActive(JSON.parse(localStorage.getItem("cartData")).setPajakActive)
            setSubDiskonActive(JSON.parse(localStorage.getItem("cartData")).setSubDiskonActive)
            setPajakNominal(JSON.parse(localStorage.getItem("cartData")).setPajakNominal)
            setSelectedDiscount(JSON.parse(localStorage.getItem("cartData")).setSelectedDiscount)
            setSubtotal(JSON.parse(localStorage.getItem("cartData")).setSubtotal)
            setSubDiskonIndex(JSON.parse(localStorage.getItem("cartData")).setSubDiskonIndex)
        }
        // Respond to the `storage` event
        function storageEventHandler(event) {
            if (event.key === "outlet") {
                setBannerUrl(JSON.parse(localStorage.getItem("outlet")).bannerUrl);
            } else if (event.key === "currentCart") {
                setCart(JSON.parse(localStorage.getItem("currentCart")))

            } else if (event.key === "cartData") {
                setDiskonActive(JSON.parse(localStorage.getItem("cartData")).setDiskonActive)
                setPajakActive(JSON.parse(localStorage.getItem("cartData")).setPajakActive)
                setSubDiskonActive(JSON.parse(localStorage.getItem("cartData")).setSubDiskonActive)
                setPajakNominal(JSON.parse(localStorage.getItem("cartData")).setPajakNominal)
                setSelectedDiscount(JSON.parse(localStorage.getItem("cartData")).setSelectedDiscount)
                setSubtotal(JSON.parse(localStorage.getItem("cartData")).setSubtotal)
                setSubDiskonIndex(JSON.parse(localStorage.getItem("cartData")).setSubDiskonIndex)
            }
        }
        // Hook up the event handler
        window.addEventListener("storage", storageEventHandler);
        return () => {
            // Remove the handler when the component unmounts
            window.removeEventListener("storage", storageEventHandler);
        };
    }, [])

    return (
        <Row className='front-screen'>
            <Col md={8} sm={8} xs={12} className='banner'>
                <div className='banner-container'><img src={bannerUrl}></img></div>
            </Col>
            <Col md={4} sm={4} xs={12} className='cart'>
                {
                    cart.length == 0 ?
                        <EmptyCart /> :
                        <>
                            <CustomerData />
                            <Transaction />
                            <SummaryCart />
                        </>
                }
            </Col>
        </Row>
    )
}

export default FrontScreen;