import React from 'react';
import { BounceLoader } from 'react-spinners';

import colors from '../../assets/styles/_colors.scss';

const LoadingScreen = () => {
  return(
    <BounceLoader height={4} width={'100%'} color={colors.blue} />
  )
}

export default LoadingScreen;