import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import Loadable from 'react-loadable';
import './App.scss';
import Api from './api-module';
import LoadingScreen from './components/LoadingScreen';
import { ROLE } from "./utils/constant";
import FrontScreen from './components/FrontScreen';

const loading = (props) => {
  if (props.pastDelay) {
    return LoadingScreen;
  } else {
    return null;
  }
}

const Login = Loadable({
  loader: () => import('./views/Login'),
  loading,
});

const Home = Loadable({
  loader: () => import('./views/Home'),
  loading,
});

const Kitchen = Loadable({
  loader: () => import('./views/Kitchen'),
  loading,
});

const Counter = Loadable({
  loader: () => import('./views/Counter'),
  loading,
});

const api = new Api();

const App = () => {
  const [accessToken, setAccessToken] = useState("")
  const [isLogin, setIsLogin] = useState()
  const [finalRole, setRole] = useState();


  const getData = () => {
    console.log(finalRole)
    if (finalRole == ROLE.KITCHEN) {
      api.getAuthData();
      api.getNewKitchenData();
      api.getProcessKitchenData();
      api.getReadyKitchenData();
    } else if (finalRole == ROLE.CASHIER) {
      api.getAuthData();
      api.getCategoryData();
      api.getMenuData();
      api.getPaymentMethod();
      api.getHistoryData();
    } else if (finalRole == ROLE.ADMIN) {
      api.getAuthData();
      api.getCategoryData();
      api.getMenuData();
      api.getPaymentMethod();
      api.getHistoryData();
      api.getNewKitchenData();
      api.getProcessKitchenData();
      api.getReadyKitchenData();
    }
  }

  const callback = (value) => {
    setIsLogin(value)
    setAccessToken(localStorage.getItem("accessToken"))
  }

  const getRole = async (value) => {
    setRole(value)
  }

  useEffect(() => {
    setAccessToken(localStorage.getItem("accessToken"))
    if (accessToken != "" && accessToken != null && accessToken != undefined) {
      setIsLogin(true);
    }
  }, [accessToken]);

  useEffect(() => {
    setRole((localStorage.getItem("role")))
    getData()
  }, [finalRole])

  useEffect(() => {
    setAccessToken(localStorage.getItem("accessToken"))
  }, [])

  return (
    <div className='App'>
      <Routes>
        <Route path='*' element={isLogin ? finalRole == ROLE.CASHIER || finalRole == ROLE.ADMIN ? <Home isLogin={callback} role={finalRole} /> : finalRole == ROLE.KITCHEN || finalRole == ROLE.ADMIN ? <Kitchen isLogin={callback} /> : <Login role={getRole} isLogin={callback} /> : <Login role={getRole} isLogin={callback} getData={getData} />} />
        <Route path='/kitchen' element={isLogin ? finalRole == ROLE.KITCHEN || finalRole == ROLE.ADMIN ? <Kitchen isLogin={callback} /> : finalRole == ROLE.CASHIER || finalRole == ROLE.ADMIN ? <Home isLogin={callback} role={finalRole} /> : <Login role={getRole} isLogin={callback} /> : <Login role={getRole} isLogin={callback} getData={getData} />} />
        <Route path='/counter/:outletId' element={<Counter />} />
        <Route path='/front-screen' element={isLogin ? finalRole == ROLE.CASHIER || finalRole == ROLE.ADMIN ? <FrontScreen /> : finalRole == ROLE.KITCHEN || finalRole == ROLE.ADMIN ? <Kitchen isLogin={callback} /> : <Login role={getRole} isLogin={callback} /> : <Login role={getRole} isLogin={callback} getData={getData} />} />
      </Routes>
    </div>
  )
}

export default App;
