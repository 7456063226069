import { createSlice } from "@reduxjs/toolkit";

const initialState = []

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        itemAdded : {
            reducer(state, action) {
                state.push(action.payload)
            },
            prepare(data) {
                return {
                    payload : data
                }
            }
        }
        
    }
})

export const selectAllCart = (state) => state.cart;

export const { itemAdded } = cartSlice.actions

export default cartSlice.reducer